/**
 * 与接口相关的工具函数
 */
import schttp from 'public/src/services/schttp'

/**
 * 批量删除
 * @param {Array, String, Number} ids 需要删除的商品的cartId
 */
export const batchDelete = ({ ids = [], page = 'cart', filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchDelete/update',
    params: {
      ids: batchIds,
      page: page
    },
    data: {
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

/**
 * 批量收藏
 * @param {Array, String, Number} ids 需要收藏的商品的cartId
 */
export const batchWishGoods = ({ ids = [], filterCheck } = {}) => {
  const batchIds = [].concat(ids)
  return schttp({
    url: '/api/cart/batchWishGoods/update',
    method: 'POST',
    data: {
      id: batchIds,
      cart_prime_product_code: window.cart_prime_product_code || ''
    }
  }).then(res => {
    if (filterCheck == 1 && res?.info?.carts?.length) {
      // 下单页无库存弹窗删除删除商品更新购物车时，在下单页过滤掉未选中的商品
      res.info.carts = res.info.carts.filter(item => item.is_checked == 1)
    }
    return res
  })
}

