<template>
  <em
    v-if="displayIcon"
    class="promotion-icon"
  >
    <sui_icon_brandsales_16px
      v-if="+item.isBrandDealProduct === 1"
      size="16px" 
      color="#FE4D00"
    />
    <!-- 有付费会员标签，不展示其他标签 -->
    <sui_icon_club_logo_fill_14px
      v-else-if="isPrime || showClubGiftOnCheckout"
      :size="primeIconSize"
      :color="primeIconV2 ? '#873C00' : '#C96E3F'"
    />
    <template v-else>
      <sui_icon_time_16px
        v-if="showTimeIcon"
        size="16px"
      />
      <sui_icon_phone_16px
        v-else-if="isAppOnly"
        size="16px"
      />
      <sui_icon_deals_arrow_16px
        v-else-if="isBrandSale"
        size="16px"
        color="#FFCD94"
      />
      <sui_icon_flashsale_16px
        v-else-if="isFlash"
        class="flash-sale-icon"
        size="16px"
      />
    </template>
  </em>
</template>

<script>
import { 
  sui_icon_club_logo_fill_14px, 
  sui_icon_time_16px, 
  sui_icon_phone_16px, 
  sui_icon_flashsale_16px, 
  sui_icon_deals_arrow_16px,
  sui_icon_brandsales_16px 
} from '@shein-aidc/icon-vue3'
export default {
  name: 'CartListItemIcon',
  components: {
    sui_icon_club_logo_fill_14px,
    sui_icon_time_16px,
    sui_icon_phone_16px,
    sui_icon_flashsale_16px,
    sui_icon_deals_arrow_16px,
    sui_icon_brandsales_16px
  },
  props: {
    item: { type: Object, default() { return  {} } },
    isPremiumMember: { type: Boolean, default() { return  false } },
    showClubGiftOnCheckout: {
      type: Boolean,
      default: false
    },
    primeIconV2: { type: Boolean, default() { return  false } },
    primeIconSize: { type: String, default: '14px' },
    isSuggested: {
      type: [String, Boolean],
      default: false
    }
  },
  computed: {
    promotionTypeIds () {
      return this.item?.promotionTypeIds || []
    },
    isPrime () {
      return this.isPremiumMember || this.promotionTypeIds.includes(29) || this.item.isSheinClubGiftItem
    },
    showTimeIcon () {
      return (
        (this.promotionTypeIds.includes(3) && !this.getIsSuggestedInversionPromotionA())
        || (this.promotionTypeIds.includes(31) && this.item?.promotionLogoTypeList?.includes(3) && !this.getIsSuggestedInversionPromotionA())
      )
    },
    // 品牌特卖
    isBrandSale() {
      return Number(this.item?.flashType) === 6 && !this.getIsSuggestedInversionPromotionA()
    },
    isFlash () {
      return this.promotionTypeIds.includes(10) && [1, 4, 5].includes(Number(this.item?.flashType)) && !this.getIsSuggestedInversionPromotionA()
    },
    isAppOnly () {
      return this.promotionTypeIds.includes(8) && !this.getIsSuggestedInversionPromotionA()
    },
    displayIcon() {
      return this.showTimeIcon
        || this.isAppOnly
        || this.isPrime
        || this.showClubGiftOnCheckout
        || this.isFlash
        || this.isBrandSale
    },
  },
  methods: {
    /**
     * 判断合规站点pl\fr 商品属于倒挂 活动A类
     * 判断合规站点de 商品属于场景1、3、5才处理促销标签 pageId=1384306511
     * @param {*} item：商品 ； isSuggested：是否合规站点； promotion：促销活动信息
     * @returns 
     */
    getIsSuggestedInversionPromotionA() {
      if(this.isSuggested == 'special_de') {
        return  ![1, 3, 5].includes(this.item.suggestedPriceType)
      }
      if (this.isSuggested && +this.item.is_inversion === 1) {
        // 如果此时商品未参与B类活动，且未参与SHEIN CLUB，则此时展示的价格为黑色
        // 如果此时商品参与了B类活动，或参与了SHEIN CLUB，则此时可以展示B类活动和SHEIN CLUB的价格颜色、活动标签、价格前的icon，和线上保持一致
        return !(this.item.isPromotionB || this.item.isSheinClubItem)
      }
      return false
    }
  }
}
</script>
<style lang="less">
.promotion-icon{
  flex-shrink: 0;
  color: @sui_color_promo;
  display: inline-flex;
  align-items: center;
  height: 100%;
  .flash-sale-icon{
    color: @sui_color_flash;
  }
}

</style>
