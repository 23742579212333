<template>
  <SPopover  
    v-model="delectBubbleVisible"
    placemen="top"
    theme="dark"
    trigger="unset"
    custom-class="checkout-mp__single_popover_container"
    show-close-icon
    :outside-close="false"
    :append-to-body="false"
    :prop-style="debubbleStyle"
    @closed="close"
  >
    <div v-if="delectBubbleInfo">
      <div
        v-expose="{
          id: 'expose_ugctags_bubble:simple',
          data: delectBubblePoint,
          once: false,
          delay: 0
        }"
        class="checkout-mp__single_popover_text"
      >
        <img :src="delectBubbleInfo.icon" />
        <span v-html="delectBubbleInfo.text"></span>
      </div>
    </div>
    <template #reference>
      <div
        ref="singleProductRef"
        class="checkout-cart__item-effiency drawer-item-effiency"
        tabindex="0"
      >
        <div class="checkout-cart__item-body">
          <!-- 如果需要修改图片裁剪尺寸的话，记得同步Brackets Zhao -->
          <CommonImage 
            class="item-img-content"
            :item="item"
            :cut-img-width="100"
            image-mask-type="oneOne"
          >
            <template #others>
              <!-- 有付费会员标签，只展示付费会员，不展示其他配套色 -->
              <div
                v-if="!isSuggested && item.product.unit_discount > 0"
                :class="[
                  discountLogoByAbt ? 'cart-discount-v2' : 'item-discount',
                  isPremiumMember ? { 'premium-member-discount': isPremiumMember } : {
                    'activity-flash': promotionContent && [1, 4, 5].includes(item.flashType),
                    'new-person-flash':
                      promotionContent && [2, 3].includes(item.flashType),
                    'promotion-discount': promotionDiscount,
                    'brand-flash': promotionContent && [6].includes(item.flashType),
                  },
                  {'club-gift-discount': isSheinClubGiftItem},
                  'not-fsp-element'
                ]"
              >
                <span v-if="GB_cssRight">-%{{ item?.product?.unit_discount }}</span>
                <span v-else>-{{ item.product.unit_discount }}%</span>
              </div>
              <!-- 敏感的商品标签 -->
              <img
                v-if="isSensitiveGoods"
                class="item-sensitive"
                :src="`${PUBLIC_CDN}/she_dist/images/checkout/sui_icon_sensitive-f1bf4c1570.svg`"
              />
              <template 
                v-if="isOutStockGood"
              >
                <div class="item-num item-num-soldout">
                  {{ language.SHEIN_KEY_PC_16524 }}
                </div>
                <div 
                  class="item-sold-out"
                ></div>
              </template>
            </template>
          </CommonImage>
          <div class="item-info-wrap">
            <s-popover
              v-if="chemicalsIds.includes(item.product.goods_sn)"
              class="item-pr65-wrap"
              placemen="top-start"
              trigger="hover"
              :append-to-body="true"
              :immediately-render="true"
              :prop-style="{ maxWidth: '270px' }"
              :delay-render="300"
            >
              <div
                v-html="tagIdsMap[item.product.goods_sn] || defaultP65TipsContent"
              ></div>
              <template #reference>
                <div
                  class="item-p65"
                  @mouseenter="showP65ItemEvt"
                >
                  <img :src="IMG_LINK['warning']" />
                  <span>{{ language.SHEIN_KEY_PC_21932 }}</span>
                </div>
              </template>
            </s-popover>
            <div
              class="product-name"
              :title="item.product.goods_name"
            >
              {{ item.product.goods_name }}
            </div>
            <div
              v-if="skuSaleAttrText"
              class="goods-color-Behavior"
            >
              <div
                class="gd-color"
                :title="skuSaleAttrText"
              >
                <span
                  v-if="item.product.main_sale_attr_show_mode == 2 && item.product.color_image && item.product.relatedColor && item.product.relatedColor.length"
                  class="value"
                >
                  <img
                    :src="transformImg({img: item.product.color_image})"
                    class="color-img"
                  />
                </span>
                <span class="attr"> {{ skuSaleAttrText }}</span>
              </div>

              <ClientOnly>
                <CartItemBehavior
                  :language="language"
                  :label="item.product.showBehaviorLabel"
                  class="behavior-wrap"
                  wrap
                />
              </ClientOnly>
            </div>

            <div class="product-tag-container">
              <!-- 百亿补贴标签 -->
              <img
                v-if="item.brandDealTag?.view?.imageInfo?.url"
                class="brand-deal-tag" 
                :src="item.brandDealTag?.view?.imageInfo?.url"
                alt=""
              />
              <!-- 榜单标签，位于用户行为标签下方 -->
              <div 
                v-if="item.rankTag?.tag"
                class="rank-tag"
              >
                <div class="rank-tag__main">
                  {{ item.rankTag?.tag?.view?.text }}
                </div>
                <div class="rank-tag__desc">
                  {{ item.rankTag?.tag?.view?.suffixText }}
                </div>
              </div>
              <!-- 黑五气氛，在商品下面显示黑五标签图 -->
              <div
                v-if="goodsNameBeforeLabelIcon"
                class="black-five-label"
              >
                <img
                  class="label-img"
                  :src="goodsNameBeforeLabelIcon"
                  alt=""
                />
              </div>
              <!-- <ClientOnly> -->
              <!-- 增加over_quick_ship标记超出qs库存商品不展示标识 -->
              <!-- <template v-if=" isQuickShipShow(item)">
              <quickShipLabel v-if="quickShipCheckoutStatus.quickShipStyle" />
            </template>
          </ClientOnly> -->
              <!-- 由于下单和购物车abt逻辑控制不一样，quickship单独出来，不用cart聚合tag组件， 直接quickship tag标签组件 -->
              <QuickShipTag
                v-if="isQuickShipShow(item)"
                :cart-qs-tag-info="{
                  ...quickShipInfoTag.qsDataProps,
                  qsTimelinessInfo: {
                    isLocationTop: false,
                  }
                }"
                :qs-mall-list="qsMallList"
                :current-item-no="item.id"
              />
              <!-- 免邮，quickship，赠品 三个标签特殊处理需要展示，其余活动标签不展示 -->
              <CartItemProductTag 
                :language="language"
                :show-quick-ship="false"
                :show-free-shipping="isShowShippingFee"
                :show-club-gift-on-checkout="isSheinClubGiftItem"
                :item="item"
                :free-lang="language.SHEIN_KEY_PC_18192"
                page="checkout"
                :abt-params="abtParams"
                :show-evolu-shein="showEvoluShein && switchOnEvoluShein"
                :show-sfs-tag="!isQuickShipShow(item) && showSfsTag"
              />
              <DayTimeTag
                v-if="source !== 'outer'"
                :card-id="item?.id"
              />
            </div>
        
            <div
              class="product-price-num"
            >
              <div
                class="cart-item-price__wrap not-fsp-element price-wrap-drawer"
              >
                <div class="item-price-content drawer-price">
                  <template v-if="!isSuggested">
                    <!-- 促销icon -->
                    <cartListItemIcon
                      :item="item"
                      :is-premium-member="isPremiumMember"
                      :show-club-gift-on-checkout="isSheinClubGiftItem"
                      :top="2"
                      :prime-icon-v2="discountLogoByAbt"
                    />
                    <em
                      v-if="!IS_RW && isVipItem"
                      :class="[GB_cssRight ? 'vip-exclusive-tag__ar' : 'vip-exclusive-tag']"
                    ></em>
                  </template>
              
                  <romwe-vip-price
                    v-if="IS_RW && isVipItem"
                    :price="item.unitPrice.amountWithSymbol"
                  />
                  <span
                    v-else
                    class="price"
                    :class="priceClassName"
                  >{{ getPriceByAbt }}</span>
                </div>
                <del 
                  v-if="!isSuggested && isShowDelPrice"
                  class="price-del"
                >
                  {{ item.product.retailPrice.amountWithSymbol }}
                </del>
              </div>
              <InputNumber 
                v-if="canEditShoppingbag"
                class="shopping-bag-options__item-number not-fsp-element input-number-drawer" 
                :item="item"
                :index="index"
                :is-last-goods="isLastGoods"
                :is-promition-goods="isPromitionGoods(item)"
                :is-out-stock-good="isOutStockGood"
                :source="source"
                :is-shein-club-gift="isSheinClubGiftItem"
                @change-number-emit="changeNumberEmit"
              />
              <div
                v-if="!canEditShoppingbag && showQuantity"
                class="checkout-cart__item-quantity"
                :class="{
                  'quantity-welfare': +item.quantity >= 2
                }"
              >
                <span>x{{ item.quantity }}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="item.product.double_status == 0"
          class="gd-footer extra-point-tip"
          da-event-expose="1-8-5-18"
          :data-skc_code="item.product.goods_sn"
        >
          <span>
            <Icon
              class="gd-footer-icon"
              name="sui_icon_info_12px_1"
              size="12px"
              color="#959595"
            />
            {{ language.SHEIN_KEY_PC_22343 }}
          </span>
        </div>
        <div
          v-if="!showCartRefundByAbt && item.return_flag && item.return_flag != 0"
          class="gd-footer"
        >
          <span>
            <Icon
              class="gd-footer-icon"
              name="sui_icon_info_12px_1"
              size="12px"
              color="#959595"
            />
            {{ language.SHEIN_KEY_PC_15990 }}
          </span>
        </div>
        <div 
          v-if="isCustomCart"
          v-tap="{
            id: '1-11-1-171', 
            data: customAnalysis
          }"
          class="item-custom-new"
          @click="openPreviewDialog"
        >
          <span>
            {{ language.SHEIN_KEY_PC_27387 }}
          </span>
          <sui_icon_more_right_12px_1
            color="#222222"
            size="12px"
          />
        </div>
        <div 
          v-if="largeShipTips"
          class="item-large-tips"
        >
          <Icon
            :name="lagreShipInfo.largeShipIcon"
            :size="'12px'"
            :color="'#198055'"
          />
          <span 
            class="large-tip-text" 
            v-html="largeShipTips"
          >
          </span>
        </div>
      </div>
    </template>
  </SPopover>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
import { SPopover } from '@shein-aidc/sui-popover/laptop'

import cartListItemIcon from 'public/src/pages/cart_new/components/list/cartListItemIcon.vue'
import CartItemProductTag from 'public/src/pages/cart_new/components/list/newList/CartItemProductTag.vue'
import RomweVipPrice from 'public/src/pages/components/productItem/components/RomweVipPrice.vue'
import InputNumber from 'public/src/pages/checkout/vue_tpls/shopping_bag/InputNumber.vue'

import DayTimeTag from 'public/src/pages/checkout/components/DayTimeTag.vue'
import { defineAsyncComponent, ref, onMounted } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { transformImg, template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose, tap } from 'public/src/pages/common/analysis/directive.js'
import CustomContentBox from 'public/src/pages/components/custom-content/index.js'
import { isJSON, handleLargeShipInfo } from 'public/src/pages/checkout/config/tools.js'
import { isPromitionGoods } from 'public/src/pages/checkout/utils.js'
import BehaviorLabel from 'public/src/pages/cart_new/utils/behaviorLabel.js'
import { sui_icon_more_right_12px_1 } from '@shein-aidc/icon-vue3'
import { getProductSize } from 'public/src/pages/checkout/utils.js'
import CommonImage from 'public/src/pages/checkout/components/CommonImage.vue'
import QuickShipTag from 'public/src/pages/cart_new/components/list/newList/QuickShipTag.vue'
import { useGetGuickShip } from 'public/src/pages/checkout/hooks/useQuickShip.js'
import { useDelectBubble } from 'public/src/pages/checkout/hooks/useParticles.js'


daEventCenter.addSubscriber({ modulecode: '1-11-1' })

export default {
  name: 'CheckoutCartItemV2',
  directives: { expose, tap },
  components: {
    CartItemProductTag,
    RomweVipPrice,
    cartListItemIcon,
    CartItemBehavior: defineAsyncComponent(() => import('public/src/pages/cart_new/components/list/newList/CartItemBehavior.vue')),
    InputNumber,
    sui_icon_more_right_12px_1,
    Icon,
    CommonImage,
    QuickShipTag,
    SPopover,
    DayTimeTag,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0
    },
    language: {
      type: Object,
      default: () => {}
    },
    chemicalsIds: {
      type: Array,
      default() {
        return []
      },
    },
    tagIdsMap: {
      type: Object,
      default() {
        return {}
      },
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    canEditShoppingbag: {
      type: Boolean,
      default: false
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    isLastGoods: {
      type: Boolean,
      default: false
    },
    source: {
      type: String,
      default: ''
    },
    shoppingBagCrossedPriceByAbt: {
      type: Boolean,
      default: false
    },
    discountLogoByAbt: {
      type: Boolean,
      default: false
    },
    underPriceAbtValue: {
      type: Boolean,
      default: false
    },
    isSuggested: {
      type: [Boolean, String],
      default: false
    },
    checkoutScene: {
      type: String,
      default: ''
    },
    locals: {
      type: Object,
      default: () => {}
    },
    switchOnEvoluShein: {
      type: [Boolean, String],
      default: false
    },
    evoluSheinId: {
      type: [Number, String],
      default: ''
    },
    showArrivalPriceByAbt: {
      type: [Boolean, String],
      default: false
    },
    largeShipExtendsInfo: {
      type: Object,
      default: () => {}
    },
    isSiteMallList: {
      type: Boolean,
      default: false
    },
    sfsTagAbt: {
      type: Object,
      default: () => {}
    }
  },
  setup(props){
    const { quickShipInfoTag, qsMallList } = useGetGuickShip()
    const { delectBubbleVisible, changeDelectBubble, delectBubbleInfo, close, delectBubblePoint  } = useDelectBubble(props.item)
    const singleProductRef = ref(null)
    const debubbleStyle = ref({})
    const changeNumberEmit = (currentValue, oldValue) => {
      if(currentValue > 0 && currentValue < oldValue){
        changeDelectBubble(true)
      }
    }
    onMounted(() => {
      setTimeout(() => {
        debubbleStyle.value =  {
          bottom: singleProductRef.value && singleProductRef.value.getBoundingClientRect().height + 'px'
        }
      }, 500)
    })
    return { singleProductRef, debubbleStyle, quickShipInfoTag, qsMallList, delectBubbleVisible, changeNumberEmit, changeDelectBubble, delectBubbleInfo, close, delectBubblePoint }
  },
  // 该文件为复用组件，尽量不要从store中传值
  computed: {
    ...mapState([
      'defaultShipping',
    ]), 
    ...mapGetters([
      'showQuickShipByAbt', 
      'quickShipCheckoutStatus',
      'defaultLocalSizeByAbt',
      'cacheLocalCountry',
      'showCartRefundByAbt',
    ]),
    isOutStockGood() {
      return this.outStockCarts[this.item.id] 
    },
    largeShipTips() {
      if(!this.lagreShipInfo.largeShipTimeDesc || this.lagreShipInfo.isLargeShipGood != 1) return ''
      return this.lagreShipInfo.largeShipTitle + ':' + this.lagreShipInfo.largeShipTimeDesc
    },
    lagreShipInfo() {
      const { isLargeShipGood = 0, largeShipTimeDesc = '', largeShipTitle = '', largeShipIcon = '' } = handleLargeShipInfo({ 
        isSiteMallList: this.isSiteMallList, 
        largeShipExtendsInfo: this.largeShipExtendsInfo, 
        mallCode: this.item.mall_code, 
        item: this.item, 
        isOrder: this.checkoutScene == 'CheckoutAgain',
      })
      return {
        isLargeShipGood,
        largeShipTimeDesc,
        largeShipTitle,
        largeShipIcon,
      }
    },
    GB_cssRight() {
      return this.locals?.GB_cssRight ?? gbCommonInfo?.GB_cssRight
    },
    IS_RW() {
      return this.locals?.IS_RW ?? gbCommonInfo?.IS_RW
    },
    IMG_LINK() {
      return this.locals?.IMG_LINK ?? gbCommonInfo?.IMG_LINK
    },
    PUBLIC_CDN() {
      return this.locals?.PUBLIC_CDN ?? gbCommonInfo?.PUBLIC_CDN
    },
    isSheinClubGiftItem() {
      let isSheinClubGift = false
      this.item?.product?.product_promotion_info?.forEach(promotion => {
        // 根据promotion ID查促销接口获得promotion_type=4且promotion_logo_type如果为8就是会员赠品商品）
        // 且promotion_product_type === 1就是赠品
        if (promotion.type_id == 4 && promotion.promotion_logo_type === 8 && promotion.promotion_product_type == '1') {
          isSheinClubGift = true
        }
      })
      return isSheinClubGift
    },
    showEvoluShein() {
      return this.item?.rules?.includes(this.evoluSheinId)
    },
    showSfsTag() {
      return this.sfsTagAbt?.visual_tabs && this.item?.sfs_flag == 1
    },
    abtParams() {
      return {
        ...this.checkout.checkoutBFFAbtInfo,
        checkoutSfsVisualAbt: {
          ...(this.sfsTagAbt || {}),
          visual_benefit_point: false
        }
      }
    },
    getPriceByAbt() {
      let originPrice = this.item?.unitPrice?.amountWithSymbol
      if(!this.showArrivalPriceByAbt) return originPrice
      
      let actualPriceObj = this.checkout?.mall_caculate_info?.cart_sub_infos?.find(item => item.cart_id == this.item.id) || {}
      const { single_sub_total } = actualPriceObj
      if(!single_sub_total) return originPrice
      return single_sub_total.amountWithSymbol
    },
    priceClassName() {
      if(!!this.isSuggested) return []

      // 优先展示付费会员色
      if(this.isPremiumMember) {
        return [
          'goods-price-prime',
          {
            'goods-price-prime__v2': this.discountLogoByAbt,
          }
        ]
      } else {
        return [
          {
            'price-discount': this.isDiscount,
            'price-vip': this.isVipItem,
            'shein-club-gift': this.isSheinClubGiftItem,
          }
        ]
      }
    },
    isShowShippingFee() {
      if(this.IS_RW) return false
      if (this.checkoutScene != 'Checkout') {
        return this.item.free_freight_flag
      } else {
        return +this.item.freight_free_rule_id > 0
      }
    },
    isDiscount() {
      let result = false
      if (this.item.product && this.item.product.retailPrice) {
        if (
          this.item.product.salePrice &&
          this.item.product.retailPrice.amount !=
            this.item.product.salePrice.amount
        ) {
          result = true
        } else if (
          this.item.unitPrice &&
          this.item.unitPrice.amount != this.item.product.retailPrice.amount
        ) {
          result = true
        }
      }
      return result
    },
    outStockCarts() {
      if (!!this.checkout?.mall_caculate_info?.outStockCarts?.length) {
        const _obj = {}
        this.checkout.mall_caculate_info.outStockCarts.forEach((item) => {
          _obj[item.id] = true
        })
        return _obj
      } else {
        return {}
      }
    },
    skuSaleAttrText() {
      const item = this.item || {}
      let result = []

      if(item.product?.sku_sale_attr?.length){
        result = item.product.sku_sale_attr.map(attr => {
          // size
          if (+attr.attr_id === 87) return getProductSize({
            abt: this.defaultLocalSizeByAbt,
            sizeAttr: attr,
            sizeRuleList: item.attr_value_location?.size_rule_list,
            cacheLocalCountry: this.cacheLocalCountry
          })
          return attr.attr_value_name
        })
        !result.length && result.push(' ')
      }
      return ` ${result.join(' / ')}`
    },
    goodsNameBeforeLabelIcon() {
      return this.item?.product?.blackFiveSignBeforeGoodsNameInfo?.goodsNameLabel?.icon || ''
    },
    promotionContent() {
      return this.item?.promotionTypeIds?.includes(10)
    },
    isPremiumMember() {
      return this.item?.promotionTypeIds?.includes(29) ||
        this.item?.discount_product_mark
        ? true
        : false
    },
    isVipItem() {
      return this.item?.promotionTypeIds?.includes(12)
    },
    defaultP65TipsContent() {
      const aDom =
        '<a href="https://www.p65warnings.ca.gov/" target="_blank">www.P65Warnings.ca.gov</a>'
      let txt = this.template(aDom, this.language.SHEIN_KEY_PC_21931)
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
      return txt
    },
    promotionDiscount(){
      const { product = {}, promotionTypeIds = [] } = this.item
      return (this.underPriceAbtValue && promotionTypeIds.includes(30)) && product.unit_discount
    },
    customizationInfo() {
      let customization_info = {}
      if (this.checkoutScene != 'Checkout') {
        customization_info = isJSON(this.item.customization_info) ? JSON.parse(this.item.customization_info) : {}
      } else {
        customization_info = this.item.product?.customization_info || {}
      }

      return customization_info
    },
    isCustomCart() {
      let canCustoms = false
      if (this.checkoutScene != 'Checkout') {
        const preview = this.customizationInfo.preview || {}
        canCustoms = this.item.customization_flag == 1 && (!!preview.effects?.length || !!preview.images?.length || !!preview.texts?.length)
      } else {
        const preview = this.customizationInfo.preview || {}
        canCustoms = this.item.product?.customization_flag == 1 && (!!preview.effects?.length || !!preview.images?.length || !!preview.texts?.length)
      }

      return !this.IS_RW && canCustoms
    },
    customAnalysis() {
      if(typeof window == 'undefined') return {}

      const preview = this.customizationInfo?.preview || {}
      return {
        images: preview?.images?.length || 0,
        preview: preview?.effects?.length || 0,
        texts: preview?.texts?.length || 0
      }
    },
    isSensitiveGoods() {
      let isSensitive = false

      if(this.checkoutScene != 'Checkout') {
        // 二次下单页
        this.checkout?.results?.mallShippingOrderMethod?.mall_list?.forEach(item => {
          if (item.mall_code === this.item.mall_code) {
            const info = item.shipping_methods[0]?.cmmdty_infos?.find(info => (
              info.skc === this.item.product.goods_sn
            ))
            isSensitive = !!info
          }
        })
      } else {
        // 下单页
        this.defaultShipping?.forEach(item => {
          if (item.mall_code === this.item.mall_code) {
            const info = item.shipping_method.cmmdty_infos?.find(info => (
              info.item_no === this.item.id
            ))
            isSensitive = !!info
          }
        })
      }
      return isSensitive
    },
    behaviorText() {
      const label = this.item.product.showBehaviorLabel
      return BehaviorLabel.getSaText(label, this.language)
    },
    isShowDelPrice() {
      if((!this.isSuggested && !this.shoppingBagCrossedPriceByAbt) || this.source == 'largerInform') return false

      return this.isVipItem || this.item.unitPrice?.amount != this.item.product.retailPrice?.amount || this.item.product.insurable == 1
    },
  },
  methods: {
    transformImg,
    template,
    isPromitionGoods,
    showP65ItemEvt() {
      daEventCenter.triggerNotice({
        daId: '1-11-1-223',
        extraData: {
          sku_code: this.item?.product?.goods_sn
        }
      })
    },
    isQuickShipShow(item){
      const findData = [this.qsMallList?.qs_mall_list_all ?? []].find(i => i.item_no === item.id) ?? {}
      const specialCountryMap = {
        br: findData.product_quickship_tag && item.mall_code == '2' && item.sfs_flag == 1,
        jp: true
      }
      const specialSiteResult = 
        Object.keys(specialCountryMap).includes(this.locals.SiteUID) 
          ? specialCountryMap[this.locals.SiteUID] 
          : [1, 2].includes(+item.real_quick_ship)
      return specialSiteResult && this.showQuickShipByAbt && this.qsMallList?.last_quickship_tag
    },
    openPreviewDialog() {
      CustomContentBox.open({
        data: this.customizationInfo || {},
      })
    },
  },
}
</script>

<style lang="less">
@s3_exclusive: '/she_dist/images/sheinvip2/s3_exclusive-3286f50076.png';
@s3_exclusive_ar: '/she_dist/images/sheinvip2/s3_exclusive_ar-7c299f990d.png';
.checkout-cart__item-effiency{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90px;
  height: 100%;
  .item-custom-new {
    display: flex;
    padding: 6px 4px;
    align-items: center;
    justify-content: space-between;
    background: #F6F6F6;
    margin-top: 6px;
    color: #222;
    font-family: "SF UI Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    &:hover {
      cursor: pointer;
    }
  }
  .gd-footer {
    display: flex;
    gap: 2px;
    align-items: flex-start;
    color: #959595;
    font-family: "SF Pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
  }
  .margin-r(5px);
  .checkout-cart__item-body {
    display: flex;
    .item-info-wrap {
      margin-left: 10px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 255px;
    }
    .product-name {
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-all;
      white-space: nowrap;

    }
    .goods-color-Behavior {
      margin-top: 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 6px;
    }
    .product-tag-container {
      display: flex;
      row-gap: 4px;
      column-gap: 4px;
      flex-wrap: wrap;
      margin-top: 6px;

      .brand-deal-tag {
        height: 18px;
        width: 86px;
      }

      .rank-tag {
        border-radius: 6px 2px 2px 1px;
        background: #FFFBEB;
        line-height: 1.5;
        padding-right: 4px;
        display: flex;
        gap: 2px;
        white-space: nowrap;
        overflow: hidden;
        font-size: 12px;

        &__main {
          border-radius: 6px 1px;
          background: #FFE8AE;
          color: #C77210;
          font-weight: 700;
          padding: 0 2px;
        }

        &__desc {
          overflow: hidden;
          color: #D58700;
          text-overflow: ellipsis;
        }
      }
    }
    .product-price-num {
      padding-right: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .item-img-content{
    position: relative;
    width: 90px;
    height: 90px;
    margin-bottom: 4px;
    .item-discount{
      
      position: absolute;
      color: @color_white_mirror;
      text-align: center;
      text-transform: lowercase;
      direction: ltr;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      & when (@IS_SH) {
        background: #222;
        width: 39px;
        top: 5px;
        left:0px;
        //.left(0px + @extra-left);
      }
      & when (@IS_RW) {
        background: @sui_color_main;
        padding: 0 2px;
        top: 0px;
        left:0px;
        color:@sui_color_white;
        font-family: Adieu-Bold, Adieu;
        font-weight: bold;
      }
      &.activity-flash {
        color: #222;
        & when(@IS_RW) {
          color: @sui_color_white;
        }
        background: @sui_color_flash;
      }
      &.premium-member-discount {
        background: #FFE2D3;
        color: #873C00;
      }
      &.new-person-flash{
        background: @sui_color_discount;
        color: @sui_color_white;
      }
      &.promotion-discount{
        background: @sui_color_promo;
        color: @sui_color_white;
      }
      &.brand-flash {
        background: @sui_color_discount;
        color: @sui_color_white;
      }
    }
    .cart-discount-v2 {
     
      position: absolute;
      color: @color_white_mirror;
      text-align: center;
      text-transform: lowercase;
      direction: ltr;
      font-size: 12px;
      height: 18px;
      line-height: 18px;
      & when (@IS_SH) {
        background: #222;
        width: 39px;
        top: 5px;
        left:0px;
        //.left(0px + @extra-left);
      }
      & when (@IS_RW) {
        background: @sui_color_main;
        padding: 0 2px;
        top: 0px;
        left:0px;
        color:@sui_color_white;
        font-family: Adieu-Bold, Adieu;
        font-weight: bold;
      }
      background: @sui_color_discount;
      color: @sui_color_white;
      & when (@IS_SH) {
        &.premium-member-discount {
          background: @sui_color_club_rosegold_dark1;
          color: @sui_color_white;
        }
      }
    }
    & when (@IS_SH) {
      .club-gift-discount {
        color: @sui_color_white;
        background-color: @sui_color_club_rosegold_dark1;
      }
    }

    .item-sensitive {
      position: absolute;
      right: 4px;
      top: 4px;
    }
    .item-num{
      position: absolute;
      width: 100%;
      // height: 20px;
      // line-height: 20px;
      padding: 2px 4px;
      background-color: rgba(0, 0, 0, 0.50);
      bottom: 0;
      text-align: center;
      font-size: 11px;
      color: @sui_color_white;
      font-weight: bold;
      z-index: @zindex-hack;
    }
    // .item-num-welfare {
    //   color: @sui_color_welfare;
    // }
    .item-num-soldout {
      background-color: rgba(0, 0, 0, .6);
      color: #fff;
    }
    .item-sold-out{
      position: absolute;
      top: 0;
      .left(0);
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, .3);
    }
  }
  .item-p65 {
    height: 16px;
    width: 66px;
    border: 1px solid rgba(255, 65, 28, 0.6);
    background: rgba(255, 231, 231, 0.7);
    display: flex;
    align-items: center;
    text-transform: uppercase;
    line-height: 15px;
    color: #FF411C;
    margin-bottom: 4px;
    font-weight: bold;
    cursor: default;
    .padding-l(2px);
    img {
      width: 10px;
      .margin-r(1px);
    }
    span {
      display: inline-block;
      font-size: 20px;
      transform: scale(0.5);
      .transform-origin-l();
      vertical-align: top;
    }
  }
  .cart-item-price__wrap {
    display: flex;
    margin-bottom: 4px;
    flex-wrap: wrap;
    align-items: center;
  }
  .price-wrap-drawer {
    margin-bottom: 0;
  }
  .item-price-content{
    display: flex;
    align-items: center;
    line-height: 1;

    font-size: 13px;
    .flash-sale-icon {
      font-size: 15px;
      color: @sui_color_flash;
      .margin-r(2px);
    }
    .promotion-icon {
      .margin-r(2px);
    }
    .price{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;

      font-weight: bold;
      color: #222;
      .padding-r(2px);
    }
    .price-discount {
      color: @sui_color_discount;
    }
    .shein-club-gift {
      color: #873C00;
    }
    .price-vip {
      color: #A78A45;
    }
    .goods-price-prime {
      color: #C96E3F;
    }
    .goods-price-prime__v2 {
      color: @sui_color_club_rosegold_dark1;
    }
  }

  .price-del {
    color: #959595;
    font-size: 10px;

    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    white-space: normal;
  }

  .black-five-label {
    display: flex;
    align-items: center;
    width: 48px;
    height: 12px;
    margin-bottom: 4px;
    .label-img {
      width: 100%;
      height: 100%;
    }
  }

  .checkout-cart-label {
    padding: 0px 6px;
    .margin-r(0);

    .label-txt {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
      white-space: normal;
      line-height: 1.5;
    }

    &:not(:first-of-type) {
      margin-top: 2px;
    }
  }

  .cart-promotion-tag {
    margin-top: 2px;
  }

  .checkout-cart__item-custom {
    color: #222;
    font-size: 12px;
    font-weight: 400;
    background: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 4px;
    cursor: pointer;
  }
  
  .checkout-cart__item-quantity {
    font-weight: 400;
    font-size: 12px;
    color: #000;
    &.quantity-welfare {
      color: @sui_color_welfare;
    }
  }
}
.vip-exclusive-tag, .vip-exclusive-tag__ar {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 34px 10px;
  width: 34px;
  height: 10px;
}
.vip-exclusive-tag {
  background-image: url(@s3_exclusive);
}
.vip-exclusive-tag__ar {
  background-image: url(@s3_exclusive_ar);
  vertical-align: -1px;
}
.drawer-price {
  font-size: 14px;
}
.product-tag-container {
  .ship-tag-omit {
    width: auto;
  }
  .quickShip {
    height: 20px;
  }
}
.item-info-wrap {
  .cart-list__promo-tag {
    max-width: 100%;
  }
  .goods-color-Behavior{
    .behavior-wrap {
      .label-text__wrap {
        width: 100%;
      }
    }
  }
}
.gd-color {
  color: @sui_color_gray_dark3;
  font-size: 12px;
  white-space: nowrap;
  .color-img {
    width: 11px;
    height: 11px;
    vertical-align: text-top;
    border-radius: 100%;
  }
  .value{
    vertical-align: middle;
  }
  .attr, .value{
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
}
.item-pr65-wrap {
  display: inline-block;
  width: 66px;
}
.input-number-drawer {
  margin-top: 0;
}
.item-price-content {
  max-width: 100%;

  .rmvip-price {
    max-width: 100%;
  }
  .rmvip-price__container {
    max-width: 100%;
    display: inline-flex;

    .rmvip-price__detail {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rmvip-price__icon {
      flex-shrink: 0;
    }
  }
}

.item-large-tips {
  color: @sui_color_highlight;
  font-size: 10px;
  margin-top: 6px;

  .large-tip-text {
    margin-left: 2px;
  }
}
.checkout-mp__single_popover_container{
  padding: 10px 20px 10px 8px;
  left: 0 !important;
  top: unset !important;
  height: fit-content !important;
  .checkout-mp__single_popover_text{
    min-width: 100px;
    max-width: var(--max-width, 280px);
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* 设置最大显示的行数 */
    word-break: break-word; /* 防止单词在换行时被分割 */
    font-family: "SF Pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    img{
      width: 14px;
      margin-right: 3px;
    }
  }
  .sui-popover__content-arrow{
    left: 43px !important;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    bottom: 0 !important;
    transform: translate(-50%,50%) rotate(45deg) !important;
    border-bottom-color: rgba(0,0,0,.8) !important;
    border-right-color: rgba(0,0,0,.8) !important;
    top: unset;
  }
}
</style>
